<div class="input-group" ngbDropdown>
  <span class="preview text-muted input-group-text" [ngStyle]="{ 'background-color': inputValue }">
    @if (!inputValue) {
      <i class="fa fa-fw fa-times"></i>
    }
  </span>

  <input
    class="form-control"
    #formInput="ngModel"
    [id]="view.vars.full_name"
    [(ngModel)]="inputValue"
    (ngModelChange)="onChange(inputValue)"
    [type]="type"
    [required]="view.vars.required"
    [maxlength]="view.vars.attr.maxlength"
    [minlength]="view.vars.attr.minlength"
    [disabled]="view.vars.disabled"
    [max]="view.vars.attr.max"
    [min]="view.vars.attr.min"
    [pattern]="view.vars.attr.pattern"
    [class.errors]="view.vars.errors.length"
    [attr.placeholder]="view.vars.attr.placeholder || 'Pick Colour'"
    />

  @if (!view.vars.disabled) {
    <a class="btn btn-outline-gray no-caret" id="actionsDropdown" ngbDropdownToggle>
      <i class="fa fa-eyedropper"></i>
    </a>
  }

  <div ngbDropdownMenu aria-labelledby="actionsDropdown" class="dropdown-menu dropdown-menu-right" role="menu">
    <compact-picker [(color)]="inputValue" (colorChange)="colorChange()"></compact-picker>
  </div>
</div>
