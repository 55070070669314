@if (form.view) {
  @if (form.view) {
    <form #formRef="ngForm" (ngSubmit)="submit()">
      @if (navItems) {
        <ngxh6-tabs
          class="mt-5"
          [containerClass]="'tab-container p-4 mb-3'"
          [navItems]="navItems"
          [content]="tabContent"
          [key]="'form-tab'"
          [small]="true"
        ></ngxh6-tabs>
      }
      <ng-template #tabContent let-id="id">
        @if (id === 'basic') {
          <fieldset ngModelGroup="basic" #basicForm="ngModelGroup">
            <sf-form name="form1" [view]="form.view" [(ngModel)]="form.model" [fields]="['name']"></sf-form>
          </fieldset>
        }
        @if (id === 'account') {
          <div class="row">
            <div class="col-6">
              <sf-form
                #accountForm
                name="form"
                rowClasses="col-12"
                [view]="form.view"
                [(ngModel)]="form.model"
                [fields]="['email', 'username', 'roles', 'locked']"
              ></sf-form>
            </div>
            <div class="col-6">
              <sf-form #accountForm name="form3" rowClasses="col-12" [view]="form.view" [(ngModel)]="form.model" [fields]="['plainPassword']"></sf-form>
            </div>
          </div>
        }
      </ng-template>
      @if (!options.sticky_actions && options.actions_separator) {
        <hr />
      }
      <ngxh6-form-actions
        [form]="form"
        [options]="options"
        [formRef]="formRef"
        [valid]="formRef.valid"
        [saving]="saving"
        (cancel)="cancel()"
        (delete)="delete()"
      ></ngxh6-form-actions>
    </form>
  }
} @else {
  <ngxh6-loading-or-error [error]="error"></ngxh6-loading-or-error>
}

