import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injectable, ViewChild } from '@angular/core';
import { NgControl, NgModel, Validator, FormsModule } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDatepicker, NgbDateStruct, NgbTimeAdapter, NgbTimeStruct, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';
import { ValueAccessorBase } from '@hutsix/ngxh6';
import * as moment from 'moment-timezone';
import { NgStringPipesModule } from 'ngx-pipes';


/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
    readonly DELIMITER = '-';

    fromModel(value: any | null): NgbDateStruct | null {
        if (value instanceof NgbDate) {
            return value;
        } else if (value instanceof Date) {
            return new NgbDate(value.getFullYear(), value.getMonth() + 1, value.getDate());
        } else if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}$/)) {
            const date = value.split('-');
            return new NgbDate(Number(date[0]), Number(date[1]), Number(date[2]));
        } else if (typeof value === 'string') {
            const date = new Date(value);
            return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): string | null {
        const d = date?.day.toString().padStart(2, '0');
        const m = date?.month.toString().padStart(2, '0');
        const y = date?.year;

        return date ? y + this.DELIMITER + m + this.DELIMITER + d : null;
    }
}

/**
 * String Time adapter
 */
const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {
    fromModel(value: string | null): NgbTimeStruct | null {
        if (!value) {
            return null;
        }
        const split = value.split(':');
        return {
            hour: parseInt(split[0], 10),
            minute: parseInt(split[1], 10),
            second: parseInt(split[2], 10),
        };
    }

    toModel(time: NgbTimeStruct | null): string | null {
        return time != null ? `${pad(time.hour)}:${pad(time.minute)}:${pad(time.second)}` : null;
    }
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-datetime',
    templateUrl: './sf-form-datetime.component.html',
    styleUrls: ['./sf-form-datetime.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    NgbDropdown,
    FormsModule,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDatepicker,
    NgbTimepicker,
    NgStringPipesModule
],
})
export class SfFormDatetimeComponent extends ValueAccessorBase implements Validator {
    @ViewChild('formInput', { static: true }) formInput: NgModel;
    @ViewChild('dp') dp: NgbDatepicker;

    public inputValue: string;
    public dateValue: string;
    public timeValue: string;
    public now = new Date();

    constructor(public cdRef: ChangeDetectorRef, public ngControl: NgControl, private calendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>) {
        super(cdRef, ngControl);
    }

    writeValue(value: any): void {
        if (value && moment(value).toDate()) {
            value = moment(value).format('YYYY-MM-DDTHH:mm');
            this.dateValue = moment(value).format('YYYY-MM-DD');
            this.timeValue = moment(value).format('HH:mm');
        }

        super.writeValue(value);
    }

    openChange($e): void {
        this.navigateToCurrent();
        if ($e) return;
        this.touch();
        this.updateInput();
    }

    updateInput(): void {
        if (!this.dateValue || !this.timeValue) {
            this.inputValue = null;
        } else {
            this.inputValue = this.dateValue + 'T' + this.timeValue;
        }
        this.onInputChange();
    }

    public selectNow(): void {
        this.writeValue(moment().toISOString());
    }

    public clear(): void {
        this.dateValue = this.dateAdapter.toModel(null);
        this.timeValue = null;
    }

    public navigateToCurrent(): boolean {
        if (!this.inputValue) return true;
        const momentDate = moment(this.inputValue);
        this.dp.navigateTo({ year: momentDate.year(), month: momentDate.month() + 1 });
    }
}
