export const environment = {
    production: true,
    env: 'staging',
    apiUrl: 'https://api.staging.rgrc.h6.run',
    azureRedirectUri: 'https://staging.rgrc.h6.run/login',
    clientId: 'adc144d6-0784-44a9-a97f-202541f0b28c',
    authority: 'https://login.microsoftonline.com/b52e0a2c-5b28-431a-9407-153d78641e40',
    whitelistedDomains: ['api.staging.rgrc.h6.run'],
    blacklistedRoutes: [],
    recaptcha_site_key: '',
};
