<div
  #ddm="ngbDropdown"
  ngbDropdown
  display="dynamic"
  placement="auto"
  container="body"
  dropdownClass="calendar-dropdown"
  (openChange)="!$event && touch(); navigateToCurrent()"
  >
  <div class="input-group">
    <input
      type="date"
      class="form-control"
      #formInput="ngModel"
      [id]="view?.vars?.full_name"
      [(ngModel)]="inputValue"
      [required]="view?.vars?.required || false"
      [maxlength]="view?.vars?.attr?.maxlength"
      [minlength]="view?.vars?.attr?.minlength"
      [pattern]="view?.vars?.attr?.pattern"
      [disabled]="!!view?.vars?.disabled"
      (ngModelChange)="onInputChange()"
      [class.errors]="view?.vars?.errors.length"
      />

    @if (!view.vars.disabled) {
      <button class="btn btn-outline-gray no-caret" ngbDropdownToggle type="button">
        <i class="fa fa-calendar"></i>
      </button>
    }
  </div>

  @if (!view.vars.disabled) {
    <div ngbDropdownMenu>
      <div class="inner">
        <div class="top p-2 px-3 border-bottom-1 d-flex d-lg-none">
          <span class="text-muted m-0">{{ view.vars.label || view.vars.name | ucfirst }}</span>
          <div class="ms-auto btn-close" (click)="ddm.close()"></div>
        </div>
        <ngb-datepicker #dp [(ngModel)]="inputValue" (ngModelChange)="calendarInputChanged(); ddm.close()" [startDate]=""></ngb-datepicker>
        <div class="d-flex actions p-2">
          <div class="btn btn-outline-danger" (click)="clear(); ddm.close()">Clear</div>
          <div class="ms-auto btn btn-outline-gray" (click)="selectToday(); ddm.close()">Today</div>
          <div class="ms-2 btn btn-gray" (click)="ddm.close()">Done</div>
        </div>
      </div>
    </div>
  }
</div>
