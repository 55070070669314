import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserServiceInterface, Ngxh6Module } from '@hutsix/ngxh6';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [Ngxh6Module],
})
export class DashboardComponent {
    constructor(@Inject('UserService') public userService: UserServiceInterface, private router: Router) {}
}
