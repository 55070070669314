import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiServiceInterface, UserServiceInterface } from '@hutsix/ngxh6';

@Component({
    selector: 'app-reload-page',
    templateUrl: './reload-page.component.html',
    styleUrls: ['./reload-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class ReloadPageComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    constructor(
        @Inject('ApiService') public api: ApiServiceInterface,
        @Inject('UserService') public user: UserServiceInterface,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        let url = '/';
        this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
            url = params.redirect;
            if (this.user.loggedIn) {
                this.router.navigateByUrl(url);
            } else {
                this.router.navigate(['/login']);
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
