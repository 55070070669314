@if (user.watch | async; as _user) {
  @if (_user.loggedIn) {
    <app-navbar></app-navbar>
  }
  <router-outlet></router-outlet>
  @if (showRequests) {
    <ngxh6-requests></ngxh6-requests>
  }
  @if (!online) {
    <div class="offline-banner">
      <div class="inner">{{ user.simulating_offline ? '(Simulating) ' : '' }}Offline - Features may be unavailable</div>
    </div>
  }
}
