import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injectable, ViewChild } from '@angular/core';
import { NgControl, NgModel, Validator, FormsModule } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDatepicker, NgbDateStruct, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { ValueAccessorBase } from '@hutsix/ngxh6';
import * as moment from 'moment-timezone';
import { NgStringPipesModule } from 'ngx-pipes';


/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
    readonly DELIMITER = '-';

    fromModel(value: any | null): NgbDateStruct | null {
        if (value instanceof NgbDate) {
            return value;
        } else if (value instanceof Date) {
            return new NgbDate(value.getFullYear(), value.getMonth() + 1, value.getDate());
        } else if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}$/)) {
            const date = value.split('-');
            return new NgbDate(Number(date[0]), Number(date[1]), Number(date[2]));
        } else if (typeof value === 'string') {
            const date = new Date(value);
            return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): string | null {
        const d = date?.day.toString().padStart(2, '0');
        const m = date?.month.toString().padStart(2, '0');
        const y = date?.year;
        return date ? y + this.DELIMITER + m + this.DELIMITER + d : null;
    }
}

@Component({
    selector: 'app-form-date',
    templateUrl: './sf-form-date.component.html',
    styleUrls: ['./sf-form-date.component.scss'],
    providers: [{ provide: NgbDateAdapter, useClass: CustomAdapter }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    NgbDropdown,
    FormsModule,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDatepicker,
    NgStringPipesModule
],
})
export class SfFormDateComponent extends ValueAccessorBase implements Validator {
    @ViewChild('formInput', { static: true }) formInput: NgModel;
    @ViewChild('dp') dp: NgbDatepicker;

    public inputValue: string;
    public now = new Date();

    constructor(public cdRef: ChangeDetectorRef, public ngControl: NgControl, private calendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>) {
        super(cdRef, ngControl);
    }

    writeValue(value: any): void {
        if (value && moment(value).toDate()) {
            value = moment(value).format('YYYY-MM-DD');
        }
        super.writeValue(value);
    }

    selectToday(): void {
        this.inputValue = this.dateAdapter.toModel(this.calendar.getToday());
        this.calendarInputChanged();
    }

    clear(): void {
        this.inputValue = this.dateAdapter.toModel(null);
        this.calendarInputChanged();
    }

    calendarInputChanged(): void {
        setTimeout(() => this.onInputChange(), 20);
    }

    navigateToCurrent(): boolean {
        if (!this.inputValue) return true;
        const momentDate = moment(this.inputValue);
        this.dp.navigateTo({ year: momentDate.year(), month: momentDate.month() + 1 });
    }
}
