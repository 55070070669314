import { enableProdMode, ErrorHandler, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule, errorHandlerFactory, jwtOptionsFactory } from './app/app.module';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { SharedModule } from './app/modules/_shared/shared.module';
import { THEMES_CONFIG } from './app/config/themes-config';
import { syncConfigFactory } from './app/config/sync-config';
import { custom_modals } from './app/config/modals-config';
import { role_hierarchy } from './app/config/users-config';
import { getFormsConfig, custom_forms, custom_filters } from './app/config/forms-config';
import { getCrudsConfig } from './app/config/cruds-config';
import { getReportsConfig } from './app/config/reports-config';
import { DebugModule, Ngxh6ServicesModule, UserServiceInterface, SYNC_CONFIG } from '@hutsix/ngxh6';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MsalInterceptor, MsalGuard, MsalModule } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpRequest } from '@angular/common/http';
import { DATE_PIPE_DEFAULT_TIMEZONE, CommonModule } from '@angular/common';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

const dbConfig: DBConfig = {
    name: 'appDb',
    version: 1,
    objectStoresMeta: [
        {
            store: 'offlineCache',
            storeConfig: { keyPath: 'ulid', autoIncrement: false },
            storeSchema: [
                { name: 'idx', keypath: ['endpoint', 'user'], options: { unique: false } },
                { name: 'endpoint', keypath: 'endpoint', options: { unique: false } },
                { name: 'user', keypath: 'user', options: { unique: false } },
                { name: 'ulid', keypath: 'ulid', options: { unique: true } },
                { name: 'data', keypath: 'data', options: { unique: false } },
            ],
        },
        {
            store: 'apiCache',
            storeConfig: { keyPath: ['endpoint', 'user'], autoIncrement: false },
            storeSchema: [
                { name: 'idx', keypath: ['endpoint', 'user'], options: { unique: true } },
                { name: 'endpoint', keypath: 'endpoint', options: { unique: false } },
                { name: 'user', keypath: 'user', options: { unique: false } },
                { name: 'timestamp', keypath: 'timestamp', options: { unique: false } },
                { name: 'data', keypath: 'data', options: { unique: false } },
            ],
        },
    ],
};
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;



if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(CommonModule, FormsModule, BrowserModule, AppRoutingModule, DebugModule, Ngxh6ServicesModule.forRoot({
            config: {
                reportConfig: {
                    reports: getReportsConfig(),
                },
                crudConfig: {
                    cruds: getCrudsConfig(),
                },
                formConfig: {
                    forms: getFormsConfig(),
                    customForms: custom_forms,
                    customFilters: custom_filters,
                },
                userConfig: {
                    roleHierarchy: role_hierarchy,
                },
                apiConfig: {
                    apiUrl: environment.apiUrl,
                },
                modalConfig: custom_modals,
                syncConfigProvider: {
                    provide: SYNC_CONFIG,
                    useFactory: syncConfigFactory,
                    deps: ['UserService'],
                },
                themeConfig: {
                    themes: THEMES_CONFIG,
                    default: 'default',
                },
            },
        }), SharedModule, JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: ['UserService'],
            },
        }), ServiceWorkerModule.register('custom-service-worker.js', { enabled: true }), NgxIndexedDBModule.forRoot(dbConfig), MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: environment.clientId,
                authority: environment.authority,
                redirectUri: environment.azureRedirectUri,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
            },
        }), {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: ['user.read'],
            },
        }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap: new Map([['https://graph.microsoft.com/v1.0/me', ['user.read']]]),
        })),
        { provide: ErrorHandler, useFactory: errorHandlerFactory },
        { provide: LOCALE_ID, useValue: 'en-AU' },
        { provide: DATE_PIPE_DEFAULT_TIMEZONE, useValue: '+0930' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        MsalGuard,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
    .catch(err => console.error(err));
