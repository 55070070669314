<nav class="navbar navbar-expand-lg navbar-dark fixed-top">
  <a class="navbar-brand d-flex align-items-center py-0 ms-2" routerLink="/">
    <img src="/assets/logo.svg" class="py-1" alt="App Logo" />
  </a>

  @if (userService.watch | async; as _user) {
    @if (_user.loggedIn) {
      <div class="ms-auto" ngbDropdown display="dynamic" placement="bottom-end">
        @if (_user.loggedIn) {
          <button class="btn profile-button" id="userDropdownMenu" ngbDropdownToggle (click)="update()">
            <!--<a class="notification" routerLink="user-admin/notifications" (click)="$event.stopPropagation()">-->
            <!--    <ngxh6-notification-badge [outline]="true"></ngxh6-notification-badge>-->
          <!--</a>-->
          <ngxh6-usericon [user]="_user.account.user" [showTooltip]="false" class="me-0"></ngxh6-usericon>
        </button>
      }
      <div ngbDropdownMenu aria-labelledby="userDropdownMenu">
        <h6 class="dropdown-header">{{ _user.account.user.name }}</h6>
        <button class="dropdown-item" routerLink="user-admin"><i class="fa fa-fw fa-user me-2"></i> My Profile</button>
        <!--<button class="dropdown-item" routerLink="user-admin/notifications">-->
        <!--    <i class="fa fa-fw fa-exclamation-circle me-2"></i> Notifications-->
        <!--    <ngxh6-notification-badge class="ms-2"></ngxh6-notification-badge>-->
      <!--</button>-->
      <div class="dropdown-divider"></div>
      <button class="dropdown-item warning" (click)="clearCaches()"><i class="fa fa-fw fa-trash me-2"></i> Clear Data Cache</button>
      @if (!_user.account.impersonating) {
        <button class="dropdown-item danger" (click)="logOut()">
          <i class="fa fa-fw fa-sign-out me-2"></i> Logout
        </button>
      }
      @if (_user.account.impersonating) {
        <button class="dropdown-item danger" (click)="userService.exitImpersonation()">
          <i class="fa fa-fw fa-sign-out me-2"></i> Exit Impersonation
        </button>
      }
      <div class="dropdown-divider"></div>
      <h6 class="dropdown-header">Theme</h6>
      @for (theme of themeService.themesKeys; track theme) {
        <button class="dropdown-item theme-selector" (click)="themeService.setTheme(theme)">
          <i
            class="fa fa-fw me-2"
            [class.fa-check-square-o]="theme === themeService.currentTheme"
            [class.fa-square-o]="theme !== themeService.currentTheme"
          ></i>
          {{ themeService.themes[theme] }}
        </button>
      }
      <div class="dropdown-divider"></div>
      @if (userService.hasRole('ROLE_ADMIN')) {
        <a class="dropdown-item" routerLink="debug"> <i class="fa fa-fw fa-bug me-2"></i> Debug</a>
      }
      <div class="dropdown-divider"></div>
      <h6 class="dropdown-header">App Version {{ version }}</h6>
      <h6 class="dropdown-header">Environment: {{ env }}</h6>
    </div>
  </div>
}
}
</nav>
